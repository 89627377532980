@import 'styles/colors.scss';

nav {

}

.Nav.section {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 1000;
  background-color: $main-brand-color;
}

.Nav .container {
  padding: 20px;
}

.Nav .content {
  max-width: 1100px;
  width: 100%;
  margin: auto;
  column-count: 2;
}

.Nav {

  a {
    color: $dark-shades;
    font-weight: 500;
  }

  a:hover {
    color: $light-shades;
  }

  button {
    color: $dark-shades;
    font-weight: 500;
    background-color: transparent;
    border: none;
  }

  button:focus {
    outline: none;
  }

  li {
    display: inline;
  }
}

li.NavMenu {
  display: none;

  i {
    position: relative;
    top: 6px;
  }
}

@media screen and (max-width: 750px) {
  li.NavMenu {
    display: inline;
  }

  .NavLeft {
    display: none;
  }

  .Nav .content {
    column-count: 1;
  }
}

.NavRight {
  text-align: right;

  li {
    padding-left: 40px;
  }
}

.NavLeft {

  li {
    padding-right: 40px;
  }
}
