@import 'styles/colors.scss';

* {
  margin: 0;
  padding: 0;
  font-family: $main-font;
}

html {
  background: $dark-shades;
}

a {
  text-decoration: none;
}

ul {
  margin: 0px;
  padding: 0px;
}

h3 {
  line-height: 25px;
}

p {
  line-height: 25px;
}

.headerSpacer {
  height: 59px;
}

@media screen and (max-width: 750px) {
  .headerSpacer {
    height: 70px;
  }
}

@function contentMinHeight($base, $exponent) {
  $result: 1;
  @for $_ from 1 through $exponent {
    $result: $result * $base;
  }
  @return $result;
}

.bodyContainer {
  min-height: calc(100vh - 148px);
}


//
// #columns {
// 	column-width: 320px;
// 	column-gap: 15px;
//   width: 90%;
// 	max-width: 1100px;
// 	margin: 50px auto;
// }
//
// div#columns .flexChild {
// 	margin: 0 2px 15px;
// 	padding: 15px;
// 	padding-bottom: 10px;
//   display: inline-block;
//   column-break-inside: avoid;
// }
//
// div#columns .flexChild img {
// 	width: 100%;
//   height: auto;
//   max-width: 750px;
// 	margin-bottom: 5px;
//   box-shadow: $dark-shadow;
// }
//
// div#columns figure figcaption {
//   font-size: .9rem;
// 	color: #444;
//   line-height: 1.5;
// }
//
// @media screen and (max-width: 750px) {
//   #columns { column-gap: 0px; }
//   #columns figure { width: 100%; }
// }
