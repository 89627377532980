@import 'styles/colors.scss';

.ContentContainer.section {
  width: 100%;
  background-color: $dark-shades;

  .container {
    padding: 20px;

    .content {
      max-width: 1100px;
      width: 100%;
      margin: auto;
    }
  }
}





p {
  margin: 0px;
  padding: 0px;
}
