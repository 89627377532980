@import 'styles/colors.scss';

.Portfolio {
  width: 100%;
  margin-top: 20px;
  // margin-bottom: 20px;
  // padding-bottom: 40px;

  h1 {
    color: $light-shades;
    font-weight: 100;
    font-size: 42px;
    padding-bottom: 20px;

    @media screen and (max-width: 750px) {
      font-size: 24px;
      font-weight: 300;
    }
  }

  .ColumnLayout {
    width: 100%;
    column-count: 3;
    margin-top: 20px;
    column-gap: 40px;
    margin-bottom: 20px;
    padding-bottom: 40px;

    @media screen and (max-width: 750px) {
      column-count: 1;
    }
  }
}
