@import "styles/colors.scss";

.BioModule {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0px;
  margin: -15px;

  // background-color: $dark-accents;
  border-radius: 5px;
}

img.headshot {
  width: 100%;
  max-width: 250px;
  border-radius: 50%;
  box-shadow: $dark-shadow;
  margin: auto;
}

.BioModule .text {
  flex: 430px;
  padding: 15px;
  font-family: $main-font;

  h1 {
    color: $light-shades;
    font-weight: 100;
    font-size: 42px;
    padding-bottom: 20px;

    @media screen and (max-width: 750px) {
      font-size: 24px;
      font-weight: 300;
    }
  }

  h3 {
    color: $main-brand-color;
    font-weight: 900;
    font-size: 16px;
    padding-bottom: 20px;
  }

  p {
    color: $light-shades;
    font-weight: 300;
    padding-bottom: 20px;
  }

  a {
    color: $light-accents;
    font-weight: 500;
  }

  a:hover {
    color: $main-brand-color;
  }

  .thin {
    font-weight: 700;
    color: $main-brand-color;
  }
}

.BioModule .image {
  padding: 15px;
  margin: auto;
}
