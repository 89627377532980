@import 'styles/colors.scss';

.mobileMenu {
  transition-duration: 1s;
  position: fixed;
  top: 70px;
  right: 0px;
  background-color: $dark-accents;
  display: none;
  height: 100vh;
  width: 90vw;
  z-index: 1000;

  @media screen and (max-width: 750px) {
    display: block;
  }

  .links {
    padding-left: 30px;
    padding-top: 30px;

    .NavLink {
      display: block;
      padding-bottom: 25px;

      a {
        color: $light-shades;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.mobileMenu.hidden {
  transition-duration: 1s;
  right: -90vw;
}
