// $light-shades: #F3F7F7;
// $light-accents: #E79FA0;
// $light-shades-trans: rgba(242, 242, 243, .2);
// $main-brand-color: #C95658;
// $main-brand-color-dark: #159172;
// $dark-accents: #8C8FA7;
// $dark-shades: #2E2940;
// $dark-shades: #F3F7F7;
// $dark-accents: #E79FA0;
// $light-shades-trans: rgba(242, 242, 243, .2);
// $main-brand-color: #C95658;
// $main-brand-color-dark: #159172;
// $light-accents: #8C8FA7;
// $light-shades: #2E2940;
$dark-shades: #EDEFED;
$dark-accents: #6E8E96;
$light-shades-trans: rgba(242, 242, 243, .2);
$main-brand-color: #0074a4;
$main-brand-color-dark: #0074a4;
$light-accents: #63666E;
$light-shades: #192034;
$dark-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
$light-shadow: 0 10px 20px -12px rgba(109, 100, 92, 0.8), 0 3px 20px 0px rgba(109, 100, 92, 0.5), 0 8px 10px -5px rgba(109, 100, 92, 0.5);
$main-font: 'Roboto', sans-serif;
$standard-transition-duration: .8s;
