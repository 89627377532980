@import "styles/colors.scss";

.Footer {
  background-color: $dark-shades;
  width: 100%;

  a {
    color: $light-shades;
  }
}

.Footer.section {
  width: 100%;

  .container {
    padding: 20px;

    .content {
      max-width: 1100px;
      width: 100%;
      margin: auto;
      column-count: 4;
      border-top: solid $light-accents 1px;
      padding-top: 20px;

      @media screen and (max-width: 750px) {
        column-count: 2;
      }

      li {
        display: block;
        padding-bottom: 10px;

      }

      ul {
        break-inside: avoid;
        page-break-inside: avoid;
      }

      a:hover {
        color: $main-brand-color;
      }
    }
  }
}
