@import "styles/colors";

.ProjectCard {
  margin-bottom: 60px;
  margin-left: 20px;
  break-inside: avoid;
  page-break-inside: avoid;
  // background-color: $light-shades;
  border: solid $light-accents 1px;
  border-radius: 5px;
  position: relative;
  // top: 20px;
  // left: 20px;

  img {
    width: 100%;
    border-radius: 5px;
    box-shadow: $dark-shadow;
    position: relative;
    top: 20px;
    left: -20px;
    margin-bottom: 20px;
  }

  h3 {
    color: $main-brand-color;
    font-weight: 900;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  p {
    color: $light-shades;
    font-weight: 300;
    padding-top: 10px;
  }

  .linkText {
    color: $light-accents;
    font-weight: 500;
    padding-top: 20px;
  }

  .linkText:hover {
    color: $main-brand-color;
  }

  .description {
    padding: 15px;
    padding-bottom: 15px;
  }
}
